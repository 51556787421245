import {
  IProjetTravaux,
} from "@/@models/projets";
import { projetsService } from "@/api";
import { isValidStatus } from "@/utils";
import { ref } from "vue";


export function useProjetTravaux(uidProjet: string) {
  const travaux = ref<IProjetTravaux[]>([]);

  async function fetch() {
    try {
      const { status, data } = await projetsService.getProjetTravaux(uidProjet);

      if (isValidStatus(status)) {
        travaux.value = data.travaux
      }
    } catch (err) {
      //
    }
    return travaux.value.length>0;
  }

  return {
    fetch,
    travaux,
  };
}
