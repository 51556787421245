export const formatEur = (value: number, withoutSymbol?: boolean): string =>
  Number(value).toLocaleString("fr-FR", {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  }) + (withoutSymbol ? "" : " €");

/**
 * Formate la valeur en sring au format "0 000 000.000"
 */
export const formatQuantite = (value: number, numberDigit?: number): string => {
  if (numberDigit) {
    return Number(value).toLocaleString("fr-FR", {
      maximumFractionDigits: numberDigit,
    });
  } else {
    return Number(value).toLocaleString("fr-FR");
  }
};

/**
 * Formate la valeur en sring au format "000 m²"
 */
export const formatSurface = (value: number | string): string => {
  return value + " m²";
};

export const isNumber = (value: any): boolean => {
  return typeof value === "number" && isFinite(value);
};

export function formatNumber(value: number, chiffreApresVirgule = 2) {
  //format 2 chiffres après la virgule
  const valueString = value.toFixed(chiffreApresVirgule);
  //place des espaces tout les 3 chiffres en partant de la droite
  return valueString.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}
